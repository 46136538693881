import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet terrós groguenc o clar més blanquinós cap a dalt. Presenta forma d’arbre o corall amb un peu en comú, sortint d’una base radicant amb rizomorfes blancs, la qual presenta a la part superior unes ramificacions dividides cada vegada de dues en dues, fins a l’àpex, dividit vàries vegades en forma de puntes en forma d’espines. Té una alçada de fins a 6 cm d’altura. Les espores són de color terrós en massa, el·líptiques amb petites berrugues, de 5-7 x 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      